<script>
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _merge from "lodash/merge";
import _isEqual from "lodash/isEqual";
import { wpService } from "@/services/wp";

const defaultFilters = {
  trade: "",
  locations: "",
  ltg_cond: "0",
  amenities: "",
  ltg_room: "0,max",
  ltg_bath: "0,max",
  ltg_park: "0,max",
  price: "0,max",
  category: "0",
  show: "12",
  order: "DESC",
  orderby: "date",
  paged: "1",
};

// function qDifferences(q1, q2) {
// }

export default {
  components: {
    entriesShowing: () =>
      import(
        /* webpackChunkName: "entries-showing" */ "@/components/entries-showing.vue"
      ),
    singleListing: () =>
      import(
        /* webpackChunkName: "single-listing" */ "@/components/single-listing.vue"
      ),
    finderEntry: () =>
      import(
        /* webpackChunkName: "finder-entry" */ "@/components/finder-entry.vue"
      ),
  },

  props: {
    org_id: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      fetching: false,
      qResult: null,
      qParams: _merge({}, defaultFilters, this.$route.query),
      lastQuery: {},
      qPaged: this.$route.query.paged ? Number(this.$route.query.paged) : 1,
      watchFilters: false,
    };
  },

  computed: {
    queryFromFilters() {
      return _merge({}, this.qParams, { paged: String(this.qPaged) });
    },
    queryFromURL() {
      return _merge({}, defaultFilters, this.$route.query);
    },
    isSingleListing() {
      if (this.$route.name !== "organization-listings-single") return null;
      let ltg_id = Number(this.$route.params.ltg_id) || 0;
      let single = {
        ltg_id: Number(this.$route.params.ltg_id) || 0,
        data: null,
      };
      if (single.ltg_id && this.qResult) {
        single.data = _find(this.qResult.entries, { ltg_id: ltg_id }) || null;
      }
      return single;
    },
  },

  methods: {
    minQuery(query) {
      // let prevQ = _merge({}, defaultFilters, this.$route.query);
      query = _merge({}, defaultFilters, query);
      let minQuery = {};
      _forEach(query, (value, key) => {
        if (query[key] !== defaultFilters[key]) {
          // console.log(key, query[key]);
          minQuery[key] = value;
        }
      });
      // console.log(minQuery);
      return minQuery;
    },
    routeQuery(query = this.queryFromFilters) {
      let lastQuery = _merge({}, this.queryFromFilters, this.lastQuery);
      query = _merge({}, lastQuery, query);
      if (!_isEqual(lastQuery, query)) {
        if (query.trade != lastQuery.trade) {
          query.price = "0,max";
        }
      }
      this.lastQuery = query;
      query = this.minQuery(query);
      if (
        this.$route.name !== "organization-listings" &&
        this.$route.name !== "organization-listings-single"
      ) {
        this.$router.replace({ name: "organization-listings", query });
      } else {
        this.$router.push({ name: "organization-listings", query });
      }
    },
    queryListings() {
      /**
       * Do not fetch listings while in a single
       */
      if (this.$route.name === "organization-listings-single") return;
      this.fetching = true;
      wpService.organizations
        .queryOrgListings(this.org_id, { params: this.queryFromFilters })
        .then(response => {
          this.qResult = response;
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    exitSingleListing() {
      this.routeQuery();
    },
    entryRoute(entry) {
      return this.$router.resolve({
        name: "organization-listings-single",
        params: {
          ltg_id: entry.ltg_id,
        },
      });
    },
  },

  watch: {
    $route(to, from) {
      if (to.name === "organization-listings-single") return;
      /* Ignore if coming from single && it has been already fetched */
      if (from.name === "organization-listings-single" && this.qResult) return;
      let prevQuery = _merge({}, defaultFilters, from.query);
      let nextQuery = _merge({}, defaultFilters, to.query);
      /* Ignore if query is the same && it has been already fetched */
      if (_isEqual(prevQuery, nextQuery)) {
        if (!this.qResult) {
          // console.log("$route change... same query but not yet fetched.");
          this.queryListings();
        }
        return;
      }
      /** If we made it here its a new query... fetch! */
      // console.log("$route watch... new query. Fetch it!");
      this.watchFilters = false;
      this.qParams = _merge({}, nextQuery);
      this.lastQuery = _merge({}, nextQuery);
      this.qPaged = Number(nextQuery.paged) || 1;
      this.$nextTick(function() {
        this.watchFilters = true;
        this.queryListings();
      });
    },
    qParams: {
      deep: true,
      handler: function(values) {
        if (!this.watchFilters || this.isSingleListing) return;
        if (this.qPaged == 1) {
          this.routeQuery(values);
        } else {
          this.qPaged = 1;
        }
      },
    },
    qPaged() {
      if (this.watchFilters) this.routeQuery();
    },
  },

  mounted() {
    this.watchFilters = true;
    this.queryListings();
  },
};
</script>

<template>
  <div>
    <div class="listings__outer" v-if="!qResult || fetching">
      <appLoading />
    </div>
    <div class="listings__outer empty" v-else-if="!qResult.entries.length">
      <div class="content">
        <p>Sin propiedades por el momento</p>
      </div>
    </div>
    <div class="listings__outer" v-else>
      <entriesShowing
        :show="Number(qParams.show)"
        :paged="qPaged"
        :showing="qResult.entries.length"
        :found="qResult.found || 0"
      ></entriesShowing>
      <div class="listings">
        <finderEntry
          class="listing"
          v-for="(entry, index) in qResult.entries"
          :key="index"
          :entry="entry"
          :to="entryRoute(entry)"
        />
      </div>
      <div class="sitioPagination" v-if="qResult.pages > 1">
        <hr />
        <b-pagination
          class="is-primary"
          :total="qResult.found"
          :current.sync="qPaged"
          :range-before="1"
          :range-after="1"
          :simple="true"
          :rounded="false"
          :per-page="Number(qParams.show)"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <b-pagination-button
            slot-scope="props"
            :page="props.page"
            :id="`page${props.page.number}`"
            tag="button"
          >
            {{ props.page.number }}
          </b-pagination-button>

          <b-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
            tag="button"
          >
            <b-icon icon="angle-left" />
          </b-pagination-button>

          <b-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
            tag="button"
          >
            <b-icon icon="angle-right" />
          </b-pagination-button>
        </b-pagination>
      </div>
    </div>
    <singleListing
      v-if="isSingleListing"
      :prefill="isSingleListing.data"
      @exit="exitSingleListing"
    />
  </div>
</template>

<style lang="scss" scoped>
.listings__outer {
  position: relative;
}

.entries_showing {
  margin-bottom: 1rem;
}

/deep/ .listings {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  .listing {
    color: #666;
  }
  .imageSwapper {
    border-radius: 5px;
    overflow: hidden;
  }
  .entry__content {
    display: flex;
    flex-wrap: wrap;
  }
  .entry-category,
  .entry-title {
    color: #222;
    font-weight: bold;
  }
  .entry-title {
    &::before {
      content: "@";
    }
  }
  .prices {
    flex: 1 0 100%;
    font-size: 14px;
  }
  .price {
    display: flex;
    .price__label {
      flex-grow: 1;
    }
  }
  @media (min-width: 40em) {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.75rem;
  }
  @media (min-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
  }
}
</style>
